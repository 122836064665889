@charset "UTF-8";

@font-face {
  font-family: "timr";
  src:url("../fonts/timr.eot");
  src:url("../fonts/timr.eot?#iefix") format("embedded-opentype"),
    url("../fonts/timr.woff") format("woff"),
    url("../fonts/timr.ttf") format("truetype"),
    url("../fonts/timr.svg#timr") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]::before {
  font-family: "timr" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: "timr" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-expand::before {
  content: "\65";
}
